.Canvas-main {
    background-color: gray;
    padding: 20px;
    
    max-height: 100vh;
    max-width: 55vw;
      
}

.App-canvas {
    background-color: white;
    border: 5px;
    border-color: black;
}
